<template>
  <div class="body-wrap">
    <div class="container container--toleft">
      <div class="WidgetGamification Box Box--Clear flex  mb-6">
        <div>
          <h1 class="text-2xl mb-3 font-bold capitalize">
            {{t('pages.gamification.prizes.title')}}
          </h1>
          <p class="text-lg mb-3">
            {{t('pages.gamification.prizes.description')}}
          </p>
          <div class="flex items-center gap-3">
            <p class="text-lg mr-2">
              {{t('pages.gamification.prizes.accumulated')}}
            </p>
            <div class="IconTextBox flex-1">
              <h2 class="text-lg mr-2">{{user.gamification_points}} {{t('global.gamification.points')}}</h2>
              <svg height="16" width="16" class="icon-currency-dollar fill-current Boxed">
                <use xlink:href="#icon-currency-dollar"></use>
              </svg>
            </div>
          </div>
        </div>
        <!--img src="../../../images/illustrations/stats.png" class="max-w-sm  ml-auto mr-auto w-full"/-->
      </div>
      <div>
        <div>
          <ul class="Prizes__List grid sm:grid-cols-4 gap-4">
            <div :id="'prize_'+prize.id" class="WidgetPrize Box Box--Clear flex flex-col justify-end"
              v-for="prize in prizes" :key="prize.id"
              :disabled="user.gamification_points < prize.points"
              :class="{ 'disabled': user.gamification_points < prize.points }"
            >
              <div class="WidgetPrize__Image" :style="{ backgroundImage: `url(${prize.url})`}" ></div>
              <div class="WidgetPrize__Bottom p-3">
                <h4 class="sm:text-xl mb-4 w-full">{{translationName(prize)}}</h4>
                <div class="flex flex-wrap sm:justify-between gap-2 items-center">
                  <div class="">
                    {{prize.points}} {{t('global.gamification.points')}}
                  </div>
                  <button class="Btn Btn--Primary"
                    :disabled="prize.stock === 0 || user.gamification_points < prize.points"
                    @click="openConfirmModal(prize)"
                    v-if="notRequested(prize)"
                  >
                    {{ prize.stock === 0 ? t('pages.gamification.prizes.out_of_stock') : t('pages.gamification.prizes.request') }}
                  </button>
                  <div class="" v-else>
                    {{t('pages.gamification.prizes.requested')}}
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <modal v-if="currentPrize" name="request" height="auto">
      <div class="v--modal--header v--modal--header--prize p-4">
        <img :src="currentPrize.url" class="max-w-sm  ml-auto mr-auto"/>
      </div>
      <template v-if="feedback">
        <div class="v--modal--body body--users p-4">
          <h2 class="text-xl">{{translationName(currentPrize)}}</h2>
          <p class="">{{ translationDescription(currentPrize) }}</p>
          <p class="mt-4 text-sm">{{t('pages.gamification.prizes.requested_success')}}</p>
        </div>
        <div class="v--modal--actions sm:flex sm:justify-end p-4">
          <button class="Btn Btn--Edit" @click="closeModal">
            {{t('actions.close')}}
          </button>
        </div>
      </template>
      <template v-else>
        <div class="v--modal--body body--users p-4">
          <h2 class="text-xl">{{translationName(currentPrize)}}</h2>
          <p class="">{{ translationDescription(currentPrize) }}</p>
          <div class="Box Box--LightGray Box--Thin mt-4" v-if="shippingAddress && phone">
            <p class="font-bold">Dati di spedizione:</p>
            <p class="text-sm">{{t('user_field.shipping_address')}}: {{shippingAddress}}</p>
            <p class="text-sm">{{t('user_field.phone')}}: {{phone}}</p>
            <p class="text-sm" v-html="t('pages.gamification.prizes.change_address')" />
          </div>
          <div class="Box Box--LightGray Box--Thin mt-4"  v-else>
            <p class="text-sm" v-html="t('pages.gamification.prizes.compile_address')" />
          </div>
           <p class="mt-2 text-sm">{{t('pages.gamification.prizes.request_confirm')}}</p>
        </div>
        <div class="v--modal--actions sm:flex sm:justify-end p-4">
          <button v-if="shippingAddress && phone" class="Btn Btn--Save" @click="requestPrize">{{t('actions.request')}}</button>
          <button class="Btn Btn--Edit ml-2" @click="closeModal">
            {{t('actions.undo')}}
          </button>
        </div>
      </template>
    </modal>

  </div>
</template>

<script>
import api from '../../api'
import { global as EventBus } from '../../js/utils/eventBus'

export default {
  name: 'AdminPrizeEdit',
  props: {
    currentUser: Object,
    prizes: Array,
    requests: Array,
    academyLang: String,
  },

  data() {
    return {
      currentPrize: null,
      user: this.currentUser,
      feedback: false,
    }
  },

  mounted() {
    console.log(this.currentUser)
    console.log(this.shippingAddress)
    console.log(this.phone)
  },

  computed: {

    userLang () {
      return this.currentUser.lang || this.academyLang
    },

  

    shippingAddress() {
      return this.currentUser.user_fields?.find(f => f.name == 'shipping_address')?.value
    },

    phone() {
      return this.currentUser.user_fields?.find(f => f.name == 'phone')?.value
    },
  },
    

  methods: {

    translationName(prize) {
      return  prize.translations.find(t => t.language == this.userLang).name
    },

    translationDescription(prize) {
      return  prize.translations.find(t => t.language ==  this.userLang).description
    },

    openConfirmModal(prize) {
      this.currentPrize = prize
      setTimeout(() => {
        this.$modal.show('request')
      }, 200)
    },
    requestPrize() {
      const elem = event.currentTarget
      EventBus.emit('loader:local:start', { elem })
      api.Prize.createRequest(this.currentPrize.id, {user_id: this.user.id})
        .then(res => {
          this.user = res;
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem })
          this.feedback = true
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem })
          this.$toastr.e(this.t('actions.error'));
        })
    },
    closeModal() {
      this.currentPrize = null
      this.$modal.hide('request')
    },
    notRequested(prize) {
      return this.requests.find((r) => r.prize_id == prize.id) == null
    },
  }
}

</script>
<style lang="scss">

  .v--modal--header--prize {
    img {
      height: 150px;
    }
  }


  .WidgetPrize {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__Image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &::before {
        content: "";
        padding-top: 64%;
        display: block;
        width: 100%;
        
      }
    }

    &__Bottom {
      color: white;
      background-image: linear-gradient(290deg, hsla(var(--color-primary)),hsla(var(--color-primary-dark)));
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        min-height: 38px;
      }
      
    }
  }
</style>