<template>
  <div>
      <div class="flex justify-between gap-2 items-center mb-2">
      <div class="flex-grow-0">
        <h1>{{t('settings.compose_your_home')}}</h1>
        <p>{{t('settings.compose_your_home_hint')}}</p>
      </div>
      <div class="flex-grow-0 flex items-center gap-2" v-if="layoutChanged">
        <button  @click="rollBack" class="Btn Btn--Delete">{{ t('actions.undo') }}</button>
        <button  @click="saveLayout" class="Btn Btn--Save whitespace-no-wrap">{{ t('actions.save') }} layout</button>
      </div>
      
      <a v-else href="/" target="_blank" class="Btn Btn--Text text-primary whitespace-no-wrap">Home</a>
    </div>

    <grid-layout
            :layout.sync="layout"
            :col-num="12"
            :row-height="180"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[10, 10]"
            :use-css-transforms="true"
            @layout-updated="layoutUpdatedEvent"
    >
        <grid-item v-for="item in layout"
                   :x="item.x"
                   :y="item.y"
                   :w="item.w"
                   :h="item.h"
                   :i="item.i"
                   :key="item.i"
                   @resized="resizedEvent"
                   @moved="movedEvent">
           <div class="flex justify-between items-center">
            <span class="font-bold"> {{t(`settings.home_layout.${item.i}`)}}</span>
            <button @click="removeElement(item.i)" class="Btn Btn--Delete Btn--Icon Btn--Icon--Small">
              <svg height="10" width="10" class="fill-current">
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
           </div>
        </grid-item>
    </grid-layout>
 

    <div class="mt-4 mb-8"  v-if="disabledItems.length > 0">
      <h2 class="px-2 mb-2">{{ t('settings.elements_removed') }}</h2>
      <ul class="grid grid-cols-3 gap-2">
        <li class="Box Box--Clear" v-for="item in disabledItems">
          <div class="flex justify-between items-center">
            <span>
              {{t(`settings.home_layout.${item.i}`)}}
            </span>
            <button @click="addElement(item.i)" class="Btn Btn--Save Btn--Icon ">
              <span>+</span>
            </button>
          </div>
        </li>
      </ul>
    </div>


    <div class="Form--Boxed mb-4">
      <div class="EditSection__Infos" v-for="translation in settings.home_translations">
        <div  v-if="selectedLang === translation.language">
          <div class="field">
            <label for="banner_text">{{t('settings.hero_text')}}</label>
            <input v-model="translation.banner_text " id="banner_text" class="" type="text" :placeholder="t('actions.write_something')"
              aria-label="Oggetto" />
          </div>
          <div class="field mb-8">
            <label for="cn-langt" class="mb-2">{{t('global.language')}}</label>
            <select v-model="selectedLang" id="cn-lang" class="Select mb-2"  aria-label="Lang">
              <option class="" v-for="lang in languages" :value="lang.code">{{ lang.code }}</option>
            </select>
          </div>
          <div class="flex justify-end">
            <button @click.prevent="updateHomeTranslation(translation)" class="Btn Btn--Save" type="submit">
              {{ t(`actions.save`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="Form--Boxed mb-4">
      <div class="field mb-10" v-if="settings.banner_text2">
        <label for="banner_text2">{{t('settings.hero_text2')}}</label>
        <vue-editor 
          id="banner_text2"
          v-model="settings.banner_text2.value" 
          :editorToolbar="customToolbar"
          class="mb-4 Editor--Short"
        >
        </vue-editor>
        <button @click="saveSetting(settings.banner_text2, 'banner_text2')" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
      </div>
      <div class="">
        <settingWithImage :setting="settings.cover_image" settingClass="academySetting" settingType="cover_image" :name="t('settings.hero_image')" />
      </div>
    </div> 


  </div>
</template>


<script>
import { global as EventBus } from '../../../js/utils/eventBus'; 
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import settingWithImage from '../../commons/settingWithImage'


export default {
  name: 'HomeLayout',
  props: {
   settings: Object,
   languages: Array,
  },
  data() {
    return {
      layout: [],
      disabledItems: [],
      layoutChanged: false,  // To track if layout has been changed
      modifiedItems: [],      // Keep track of modified widgets (added or removed)
      selectedLang: 'it',
    }
  },

  mounted() {
    this.getWidgets()
    console.log(this.settings)
  },
  
  components: {
    settingWithImage
  },
  computed: {
  },
  
  methods: {

    saveSetting(setting, settingType, reload) {
      this.$emit('saveSet', '', setting, settingType, reload);
    },

    updateHomeTranslation(translation) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      console.log(translation)
      api.AcademySetting.updateHomeTranslation( translation.academy_setting_id, {
        translation_id: translation.id,
        banner_text: translation.banner_text,
      })
        .then(res => {
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    // Fetch the current layout and disabled widgets
    getWidgets() {
      api.HomeLayout.getWidgets()
      .then((response) => {
        this.layout = response.enabled
        this.disabledItems = response.disabled
      })
      .catch((error) => {
        console.log(error)
      })
    },

    // Track resize event (if needed for future use)
    resizedEvent(i, newH, newW, newHPx, newWPx) {
      console.log("RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx);
      this.layoutChanged = true;
    },

    // Track move event, which should trigger the "Save" button visibility
    movedEvent(i, newX, newY) {
      console.log("MOVED i=" + i + ", X=" + newX + ", Y=" + newY);
      // Mark layout as changed
      this.layoutChanged = true;
    },

    layoutUpdatedEvent(newLayout) {
      console.log(newLayout)
    },

    // Rimuovere un widget dal layout
    removeElement(widget_key) {
      // Trova il widget da rimuovere
      const widget = this.layout.find(item => item.i === widget_key);

      if (widget) {
        // Aggiorna il layout
        this.layout = this.layout.filter(item => item.i !== widget_key);

        // Aggiorna la lista disabledItems
        this.disabledItems = [...this.disabledItems, widget];

        // Aggiorna la lista modifiedItems
        this.modifiedItems = [...this.modifiedItems, { widget_key, action: 'remove' }];

        // Segnala che il layout è cambiato
        this.layoutChanged = true;
      }
    },

    // Aggiungere un widget di nuovo nel layout
    addElement(widget_key) {
      // Trova il widget da aggiungere
      const widget = this.disabledItems.find(item => item.i === widget_key);

      if (widget) {
        // Aggiorna il layout
        this.layout = [...this.layout, widget];

        // Rimuovi il widget dalla lista disabledItems
        this.disabledItems = this.disabledItems.filter(item => item.i !== widget_key);

        // Aggiorna la lista modifiedItems
        this.modifiedItems = [...this.modifiedItems, { widget_key, action: 'add' }];

        // Segnala che il layout è cambiato
        this.layoutChanged = true;
      }
    },


  saveLayout() {
    const target = event.currentTarget;
    EventBus.emit('loader:local:start', { elem: target });

    // Creiamo due array per le promesse di salvataggio
    const layoutPromises = this.layout.map((item) => {
      return api.HomeLayout.update({
        widget_key: item.i,
        x: item.x,
        y: item.y,
        width: item.w,
        height: item.h
      });
    });

    const modifyPromises = this.modifiedItems.map(item => {
      if (item.action === 'remove') {
        return api.HomeLayout.update({ widget_key: item.widget_key, enabled: false });
      } else if (item.action === 'add') {
        return api.HomeLayout.update({ widget_key: item.widget_key, enabled: true });
      }
    });

    // Combina entrambe le serie di promesse
    const allPromises = [...layoutPromises, ...modifyPromises];

    // Usa Promise.all per aspettare che tutte le promesse siano completate
    Promise.all(allPromises)
      .then((responses) => {
        // Se tutte le promesse sono risolte (successo)
        console.log("Layout saved successfully!");
        this.$toastr.s(this.t('actions.saved')); // Mostra il messaggio di successo
      })
      .catch((error) => {
        // Se una qualsiasi chiamata fallisce, qui possiamo loggare l'errore
        console.log("Errore nel salvataggio del layout:", error);
        this.$toastr.e(this.t('actions.error')); // Puoi impostare un messaggio di errore
      })
      .finally(() => {
        // In ogni caso, ferma il loader
        EventBus.emit('loader:local:end', { elem: target });

        // Una volta salvato, resettiamo layoutChanged e modifiedItems
        this.layoutChanged = false;
        this.modifiedItems = [];
      });
  },

    rollBack() {
      // Reset the layout to the original state
      this.getWidgets();
      this.layoutChanged = false;
      this.modifiedItems = [];
    }
  }
}
</script>
