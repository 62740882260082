make a vue template
```
<template>
  <div>
    <h1 class="text-2xl font-bold capitalize">{{ t('pages.admin.hello') }}  {{user.firstName}}</h1>
    <p class="text-lg mb-4">{{t('pages.admin.go_to_home')}} <a href="/" class="Btn Btn--Text">Home</a></p>
    <div class=" grid   md:grid-cols-6 col-gap-4 row-gap-8">

      <div class="Box Hero md:col-span-2">
        <BgTitle :title="t('pages.home.build_academy')" icon="wrench"/>
        <div>
          <ul class="list-unstyled">
            <li class="flex items-center justify-between font-bold mb-4">{{ t('actions.create_course') }}<a href="/admin/courses/new" class="Btn Btn--Primary">{{ t('actions.create')}}</a></li>
            <li class="flex items-center justify-between font-bold mb-4">{{ t('actions.create_path') }}<a href="/admin/courses/new?path=true" class="Btn Btn--Primary">{{t('actions.create')}}</a></li>
            <li class="flex items-center justify-between font-bold">{{  t('actions.customise_platform') }}<a href="/admin/academy_settings" class="Btn Btn--Primary">{{ t('actions.go') }}</a></li>
          </ul>
        </div>
      </div>

      <div class="Box Box--Clear md:col-span-4">
        <BgTitle :title="t('pages.stats.more_popular_courses')" icon="chart-bar" :noBottom="true" />
        <div>
          <ul class="List List--Lined">
            <li class="font-bold" v-for="course in favouriteCourses" :key="course.id">
              <a :href="`/admin/courses/${course.id}/edit`" class="flex items-center justify-between">
                <span>
                  {{ course.name }}
                </span>
                <span class="Btn Btn--Primary Btn--Icon">
                  <svg height="16" width="16" class="fill-current"> 
                    <use xlink:href="#icon-arrow-right"></use>
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="Box Box--Clear md:col-span-2">
        <BgTitle :title="t('pages.stats.numbers')" icon="chart-bar"/>
        <div class="NumbersBox grid grid-cols-3 text-center gap-2">
          <div class=" bg-primarySuperLight rounded-lg p-2 flex flex-col items-center justify-center">
            <p class="font-bold text-3xl text-primary">{{ numbers.users }}</p>
            <p class="">
              {{ t('global.users') }}
            </p>
          </div>
          <div class="bg-primarySuperLight rounded-lg p-2 flex flex-col items-center justify-center">
            <p class="font-bold text-3xl text-primary">{{ numbers.courses }}</p>
            <p class="">
              {{ t('global.courses.p') }}
            </p>
          </div>
          <div class="bg-primarySuperLight rounded-lg p-2 flex flex-col items-center justify-center">
            <p class="font-bold text-3xl text-primary">{{ numbers.lectures }}</p>
            <p class="">
              {{ t('global.lectures.p') }}
            </p>
          </div>
        </div>
      </div>

      <div class="Box Box--Clear md:col-span-2">
        <div class="Box__BgTitle">
          <svg height="16" width="16" class="icon-checkmark-outline fill-current Boxed">
            <use xlink:href="#icon-chart-bar"></use>
          </svg>
          <h2 class="text-lg">{{t('pages.stats.more_popular_areas')}}</h2>
        </div>
        <div>
          <ul v-if="enrollsByArea.length > 0" class="list-unstyled flex flex-col gap-2">
            <li v-if="area[0]" v-for="(area, index) in enrollsByArea" :key="index" class="text-primary rounded-lg bg-primarySuperLight p-2 flex items-center justify-between font-bold">{{ area[0] }}</li>
          </ul>
          <p v-else>{{t('pages.stats.no_areas')}}</p>
          
        </div>
      </div>

      <div class="Box Box--Clear md:col-span-2">
        <div class="Box__BgTitle">
          <svg height="16" width="16" class="icon-checkmark-outline fill-current Boxed">
            <use xlink:href="#icon-chart-bar"></use>
          </svg>
          <h2 class="text-lg">{{t('pages.stats.active_users')}}</h2>
        </div>
        <div class="">
          <div class="mb-2">
            <p class="">
              <span class="font-bold text-5xl text-primary mr-1">{{ activeUsers }}</span>
              <span class="font-bold text-xl text-primary lowercase">{{t('pages.stats.active_users')}}</span>
            </p>
            <p class="text-sm text-grayScale-middle">
             {{t('pages.home.last_7_days')}}
            </p>
          </div>
          <div class="chart-wrapper relative">
            <svg xmlns="http://www.w3.org/2000/svg" class="chart-svg-2 absolute" width="341" height="48" viewBox="0 0 341 48" fill="none">
              <g filter="url(#filter0_d_16_571)">
                <path d="M7 28L30.1834 34.4517C30.2983 34.4836 30.418 34.4948 30.5368 34.4846L53.5143 32.5171C53.6463 32.5058 53.7792 32.5209 53.9054 32.5614L76.8069 39.9151C76.98 39.9706 77.1649 39.978 77.3418 39.9363L100.139 34.5681C100.328 34.5237 100.5 34.4252 100.634 34.2848L123.325 10.4833C123.604 10.1907 124.032 10.0948 124.409 10.2403L146.969 18.9437C147.084 18.988 147.206 19.0108 147.329 19.0108L170.24 19.0107C170.411 19.0107 170.578 18.9672 170.727 18.8844L193.446 6.2291C193.706 6.08401 194.018 6.06271 194.296 6.17103L216.977 15.0151C217.133 15.0758 217.301 15.0965 217.467 15.0755L239.139 12.3216C239.372 12.292 239.608 12.3455 239.806 12.4728L263.801 27.9181C263.886 27.9724 263.978 28.0137 264.074 28.0406L286.989 34.4173C287.182 34.4712 287.387 34.4655 287.578 34.401L310.253 26.7129C310.503 26.6282 310.776 26.6457 311.013 26.7616L334 38" stroke="#7F6DF8" stroke-width="2"/>
              </g>
              <defs>
                <filter id="filter0_d_16_571" x="0.731873" y="0.102707" width="339.707" height="47.8602" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="1"/>
                  <feGaussianBlur stdDeviation="3"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.921569 0 0 0 0 0.909804 0 0 0 0 0.988235 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16_571"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_16_571" result="shape"/>
                </filter>
              </defs>
            </svg>
            <svg class="chart-svg-2 w-full" xmlns="http://www.w3.org/2000/svg" width="327" height="45" viewBox="0 0 327 45" fill="none">
              <path d="M327 44V32.6223C327 32.2413 326.784 31.8934 326.442 31.7251L304.016 20.6835C303.777 20.566 303.501 20.5484 303.25 20.6346L280.579 28.3995C280.388 28.465 280.182 28.471 279.987 28.4169L257.074 22.0405C256.978 22.0136 256.886 21.9725 256.802 21.9184L232.806 6.49749C232.608 6.37033 232.372 6.31698 232.139 6.34682L210.468 9.11866C210.302 9.13994 210.132 9.11903 209.976 9.05783L187.298 0.172555C187.019 0.0633205 186.706 0.0847773 186.444 0.231027L163.728 12.9456C163.578 13.0291 163.41 13.073 163.239 13.073L140.33 13.073C140.206 13.073 140.084 13.05 139.968 13.0051L117.41 4.24238C117.032 4.09571 116.603 4.19131 116.324 4.4845L93.6337 28.2848C93.4999 28.4252 93.328 28.5237 93.1392 28.5681L70.3418 33.9363C70.1649 33.978 69.98 33.9706 69.8069 33.9151L46.9054 26.5614C46.7792 26.5209 46.6463 26.5058 46.5143 26.5171L23.5347 28.4848C23.4172 28.4949 23.299 28.4841 23.1853 28.453L1.26406 22.4513C0.627701 22.2771 0 22.7561 0 23.4158V44C0 44.5523 0.447706 45 0.999991 45H326C326.552 45 327 44.5523 327 44Z" fill="url(#paint0_linear_16_570)" fill-opacity="0.6"/>
              <defs>
                <linearGradient id="paint0_linear_16_570" x1="163.5" y1="18.5526" x2="163.5" y2="45" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#A89CFA"/>
                  <stop offset="1" stop-color="#A89CFA" stop-opacity="0"/>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import api from '../../../api'
import BgTitle from '../../commons/bgTitle.vue';

export default {
  name: 'Dashboard',
  props: {
    user: Object,
    activeUsers: Number,
    numbers: Object,
  },
  components: {
    BgTitle,
  },
  data() {
    return {
      message: 'Hello Vue!',
      enrollsByArea: [],
      enrollsByCourses: [],
    }
  },

  mounted() {
    this.getEnrollsbyArea()
    this.getEnrollsByCourses()
  },

  computed: {
    favouriteCourses() {
      return this.enrollsByCourses.slice(0, 3)
    },
  },

  methods: {
    getEnrollsbyArea() {
      console.log('getEnrollsbyArea')
      api.Stats.getEnrollsByArea()
        .then( res => {
          this.enrollsByArea = res
          //res is [[area, count], [area, count]]. get first 3 and order by count
          this.enrollsByArea = res.slice(0, 3).sort((a, b) => b[1] - a[1])
          
        }).catch(err => {
          this.err = err
        })
    },

    getEnrollsByCourses() {
      api.Stats.getEnrollByCourses({
        page: 1,
        search: '',
      })
      .then( res => {
        this.enrollsByCourses = res.enrolls_by_courses

      }).catch(err => {
        this.err = err
      })
    },
  }
}
</script>

<style scoped>
.NumbersBox {
  height: calc(100% - 57px);
}

.chart-svg-2.absolute {
  top: -7px;
  left: -7px;
  width: calc(100% + 14px);
  path {
    stroke: hsl(var(--color-primary));
  }
}
</style>