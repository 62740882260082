<template>
  <div>

    <div v-if="lecture.live && lecture.lecture_type_id !== 6" class="Form--Boxed mb-4">
      <editLiveContent   :content="vimeoLiveContents[0]" v-if="vimeoLiveContents.length > 0" />

      <editZoomUrlContent :content="zoomUrlContent" v-if="zoomUrlContent" />

      <editBbbContent 
        v-if="BbbContent && (!zoomUrlContent || ( zoomUrlContent &&  ( zoomUrlContent.content_code === '' || !zoomUrlContent.content_code ) ) ) " 
        :lecture="lecture" 
        :room="room" 
        :recordings="recordings" />

      
      <editZoomContent v-if="ZoomContent" :lecture="lecture" :content="ZoomContent" />
      <editTeamsContent v-if="TeamsContent" :lecture="lecture" :content="TeamsContent" />

      <div v-if="zoomRecordingsContents.length > 0" class="mt-6">
        <label>{{t('pages.lecture.zoom_recording')}}:</label>
        <a 
          v-for="(content, index) in zoomRecordingsContents" 
          :key="index"
          :href="content.content_code"
          class="block"
        >
          {{ content.content_code }}
        </a>
      </div>

      <div class="Form--MaxWidth mt-6">
        <label for="">{{t('pages.lecture.send_link_title')}}</label>
        <p class=" text-grayScale-middle mb-4">{{t('pages.lecture.send_link_subtitle')}}:</p>
        <div class="relative">
          <input type="text" disabled="true" id="invite-link"  v-model="liveUrl">
          <button class="js-clip" :data-clipboard-text="liveUrl">
            <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-clipboard"></use>
              </svg>
          </button>
        </div>
      </div>

    </div>

    <div v-if="lecture.lecture_type_id == 5">
      <div class="Form--Boxed mb-4">
        <div class="field">
          <label>{{t('pages.lecture.google_form.id')}}</label>
          <p v-if="lecture.form_id" class="text-gray-500 text-md mt-1">
            {{ t('pages.lecture.google_form.edit_instructions') }}
          </p>
          <p class="text-gray-700 mt-3">
            <a 
              v-if="lecture.form_id" 
              :href="'https://docs.google.com/forms/d/' + lecture.form_id + '/edit'" 
              target="_blank" 
              class="underline"
            >
              {{ lecture.form_id }}
            </a>
            <span v-else>
              {{ t('pages.lecture.google_form.no_id') }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div v-if="lecture.lecture_type_id == 6">
      <div class="Form--Boxed mb-4">
        <div class="field">
          <label>{{ t('pages.course.add_live_in_person') }}</label>
          <p class="text-gray-700 mt-1">
            <strong>{{ t('global.name') }}:</strong> {{ lecture.name }}
          </p>
          <p class="text-gray-700 mt-1">
            <strong>{{ t('global.start_date') }}:</strong> {{ new Date(lecture.start_date).toLocaleString() }}
          </p>
          <p class="text-gray-700 mt-1">
            <strong>Location:</strong> {{ (contents.filter(content => content.content_type === 'attendance').pop() || {}).content_code || 'Non specificato' }}
          </p>
        </div>
      </div>
    </div>

    <div class="Form--Boxed mb-4">
      <h4 class="mb-2">Video</h4>      
      <div v-if="vimeoContents.length > 0">
        <div  v-for="(content, index) in vimeoContents" :key="content.id" class="mb-4" >
          <div v-if="index === 0 && videoUploaded">
            <p>{{t('pages.course.upload_video_success')}}</p>
          </div>
          <div v-else>
            <vue-vimeo-player v-if="content.content_code" @ready="onVideoReady" :video-id='content.content_code' ></vue-vimeo-player>
            <button 
              class="Btn Btn--Delete mt-4"
              @click="deleteContent(content)" >
              {{t('actions.delete')}}
            </button> 
          </div>
        </div>
      </div>
      <div v-else-if="youtubeContents.length > 0">
        <div  v-for="(content, index) in youtubeContents" :key="content.id" class="mb-4" >
          <iframe width="420" height="315"
          :src="`https://www.youtube.com/embed/${content.content_code}`">
          </iframe>
          <button class="Btn Btn--Delete mb-2 mt-3" @click="deleteContent(content)">{{t('actions.delete')}}</button>
        </div>
      </div>
      <div v-else>
        <div>
          <ul>
            <li v-for="(file, index) in videos" :key="index">
              <p class="mb-2">{{ t('pages.course.upload_video_wait') }}</p>
              <div class="progress mb-2" v-if="file.active">
                <progressBar :progress="1" :indeterminate="true" />
                <!-- <div :style="{width: file.progress + '%'}">{{file.progress}}%</div> -->
              </div>
            </li>
          </ul>
          <file-upload
              ref="uploadVimeo"
              v-model="videos"
              post-action="/admin/contents"


              :chunk-enabled="true"
              :chunk="{
                action: '/admin/contents/upload_video',
                minSize: 50 * 1048576,
                maxActive: 1,
                maxRetries: 5,

                startBody: {
                  lecture_id: lecture.id,
                  course_id: course.id,
                  content_type: 'vimeo_chunk'
                },
              }"

              accept="video/*"
              :data="{course_id: course.id, lecture_id: lecture.id, content_type: 'vimeo'}"
              :drop="true"
              @input-file="inputFile"
              input-id="uploadVimeo"
            >
            {{ t('pages.course.upload_video') }}
          </file-upload>
        </div>
        <div v-if="youtubeContents.length === 0">
          <button v-if="!openExtVideo" class="Btn Btn--Save" @click="openExtVideo = !openExtVideo">{{t('pages.course.add_ext_video')}}</button>
          <button v-else class="Btn Btn--Delete mb-3" @click="openExtVideo = !openExtVideo">{{t('pages.course.close_add_ext_video')}}</button>
          <form v-if="openExtVideo">
            <div class="grid grid-cols-2 gap-2">
              <div>
                <label for="videoUrl">Video ID</label>
                <input type="text" v-model="videoId" id="video-source" :placeholder="t('pages.course.ext_video_placeholder')" />
              </div>
              <div>
                <label for="videoUrl">{{t('pages.course.source')}}</label>
                <select v-model="videoSource" id="video-source" class="Select mb-4"
                  aria-label="Tipo di evento">
                  <option value="">{{t('helpers.select.prompt')}}</option>
                  <option  :value="'youtube'">YouTube</option>
                </select>
              </div>
            </div>
            <button class="Btn Btn--Save" :disabled="!videoSource || !videoId" @click.prevent="addExtVideo({content_code: videoId, content_type: videoSource})">Salva</button>
          </form> 
        </div> 
      </div>
     
   
     
    </div>


    <div class="Form--Boxed mb-4" v-if="!lecture.live">
      <h4 class="mb-2">PDF</h4>
      <div v-for="content in pdfContents" :key="content.id" class="mb-4">
        <pdfViewer :content="content" />
        <button 
          class="Btn Btn--Delete"
          @click="deleteContent(content)" >
          {{t('actions.delete')}}
        </button> 
      </div>
      <div v-if="pdfContents.length === 0" class="mb-4">
        <uploadAttachment :course="course" :lecture="lecture" attachmentType="pdf" />
      </div>
    </div>

    <div class="Form--Boxed mb-4" >
      <h4 class="mb-2">{{t('pages.lecture.attachments')}}</h4>
      <div v-for="content in attachmentContents" :key="content.id" class="mb-4">
        <div class="mb-2">
          <a :href="content.url" target="blank">{{ content.filename }}</a>
        </div>
        <button 
          class="Btn Btn--Delete"
          @click="deleteContent(content)" >
          {{t('actions.delete')}}
        </button> 
      </div>
      <uploadAttachment :course="course" :lecture="lecture" attachmentType="attachment"  /> 
    </div>
  </div>
</template>

<script>
// import Vue from 'vue/dist/vue.esm'
 
import { mapState, mapActions } from 'vuex'
import { vueVimeoPlayer } from 'vue-vimeo-player'

import uploadAttachment from '../../commons/uploadAttachment'
import pdfViewer from '../../commons/pdfViewer'
import progressBar from '../../commons/progressBar'

import editLiveContent from'../lecture/editLiveContent'
import editBbbContent from'../lecture/editBbbContent'
import editZoomContent from'../lecture/editZoomContent'
import editTeamsContent from'../lecture/editTeamsContent'
import editZoomUrlContent from'../lecture/editZoomUrlContent'
import api from '../../../api'

import { global as EventBus } from '../../../js/utils/eventBus';

// const VueUploadComponent = require('vue-upload-component')
// Vue.component('file-upload', VueUploadComponent)
// Vue.config.silent = true

export default {
  props: {
    course: Object,
    lecture: Object,
    room: Object,
    recordings: Object,
    multipleVideosEnabled: Boolean
  },
  components: {
    vueVimeoPlayer,
    pdfViewer,
    uploadAttachment,
    progressBar,
    editLiveContent,
    editBbbContent,
    editZoomContent,
    editTeamsContent,
    editZoomUrlContent
  },
  data() {
    return {
      videos: [],
      files: [],
      videoUploaded: false,
      videoReady: false,
      liveUrl: `${window.location.origin}${this.course.url}/lectures/${this.lecture.id}`,
      openExtVideo: false,
      videoSource: '',
      videoId: '',
    }
  },
  computed: {
    ...mapState({
      contents: state => state.lecture.contents
    }),
    vimeoContents() {
      return this.contents.filter((content) => {
        return content.content_type === 'vimeo'
      })
    },
    vimeoLiveContents() {
      return this.contents.filter((content) => {
        return content.content_type === 'vimeo_live'
      })
    },
    youtubeContents() {
      return this.contents.filter((content) => {
        return content.content_type === 'youtube'
      })
    },
    BbbContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'bbb'
      })[0]
    },
    ZoomContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'zoom'
      })[0]
    },
    TeamsContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'teams'
      })[0]
    },
    zoomUrlContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'zoom_url'
      })[0]
    },
    zoomRecordingsContents() {
      return this.contents.filter((content) => {
        return content.content_type === 'zoom_recording'
      })
    },
    pdfContents() {
      return this.contents.filter((content) => {
        return content.content_type === 'pdf'
      })
    },
    attachmentContents() {
      return this.contents.filter((content) => {
        return ( content.content_type === 'attachment' )
      })
    }

  },
  mounted() {
    this.fillContents(this.lecture.contents)
    console.log('lecture', this.lecture)
  },
  methods: {
    ...mapActions({
      fillContents: 'fillContents',
      addContent: 'addContent',
      deleteContent: 'deleteContent'
    }),

    inputFile(newFile, oldFile) {
      this.videoUploaded = false
      this.$refs.uploadVimeo.active = true
       if (newFile) { 
         if (!newFile.active && newFile.success && newFile.response) {
            this.addContent(newFile.response)  
            this.videoUploaded = true
         }
       }   
    },

    async addExtVideo() {
      console.log('addExtContent', this.videoId, this.videoSource)
      const target = event.currentTarget ;
      EventBus.emit('loader:local:start', { elem: target })
      try {
        const res = await api.Content.addExternalVideo({
          lecture_id: this.lecture.id,
          course_id: this.course.id,
          content_code: this.videoId,
          content_type: this.videoSource
        })
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target })
        location.reload()
      } catch (error) {
        this.$toastr.e(this.t('errors.messages.try_again'));
        EventBus.emit('loader:local:end', { elem: target })
      }
    },

    onVideoReady() {
      this.onVideoReady = true;
    },

    
    
  }
}
</script>
