import { pick } from "lodash";
import api from "./instance";

const update = (id, params) =>
  api
    .put(`/admin/gamification/prizes/${id}`, params)
    .then((response) => response.data);

const updateTranslation = (id, params) =>
  api
    .put(`/admin/gamification/prizes/${id}/update_translation`, params)
    .then((response) => response.data);

const requestList = (id, params) =>
  api
    .get(`/admin/gamification/prizes-requests`)
    .then((response) => response.data);

const setRequestStatus = (id, value) =>
  api
    .post(`/admin/gamification/prizes-requests/${id}`, {status: value})
    .then((response) => response.data);

const createRequest = (id, params) =>
  api
    .post(`/gamification/prizes/${id}/request`, params)
    .then((response) => response.data);
  
const updateStock = (prizeId, data) => {
    api
      .put(`/admin/gamification/prizes/${prizeId}/update_stock`, data)
      .then((response) => response.data);
  }

export default {
  update,
  updateTranslation,
  requestList,
  setRequestStatus,
  createRequest,
  updateStock
};
