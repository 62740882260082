<template>
  <div>
    <div class="Form--Boxed mb-4">
      <h3 class="mb-4">{{t('pages.custom_notifications.title')}}</h3>
      <a  class="Btn Btn--Save" href="/admin/custom_notifications">{{t('settings.go_to_notifications')}}</a>
    </div>
    <div class="Form--Boxed">
      <h3 class="mb-1">Configura il mittente</h3>
      <p class="text-grayScale-middle">Puoi scegliere il mittente da cui partiranno le email standard (invito in piattaforma, iscrizione ai corsi , ecc..) e le notifiche personalizzate. Il mittente di default è no-reply@skillsincloud.com.</p>
      <p class="text-grayScale-middle mb-4">Una volta inserito, <strong>contattaci per abilitare il dominio.</strong> </p>
      <div class="flex items-center justify-between gap-4">
        <input type="text" id="sender_email" placeholder="info@yourdomain.com"  class="" v-model="settings.sender_email.value" />
        <button @click="saveSetting(settings.sender_email, 'sender_email')" class="Btn Btn--Save">{{t('actions.save')}}</button>
      </div>
      <!-- 
      <template v-if="settings.sender_email.value !== '' && settings.sender_email.value !== null">
        <div v-if="settings.sender_email_enabled.value === 'true'" class="mt-4 border-l-4 border-success text-success text-success px-4 py-1" role="alert">
          <p class="font-bold">Dominio abilitato</p>
          <p>I tuoi utenti riceveranno le email da {{ settings.sender_email.value }}</p>
        </div>
        <div v-else class="mt-4 border-l-4 border-warning text-warning text-orange-700 px-4 py-1" role="alert">
          <p class="font-bold">Dominio non abilitato</p>
          <p>Contattaci per autenticare il tuo dominio</p>
        </div>
      </template>
      -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'Notifications',
  props: {
   settings: Object
  },
  data() {
    return {
      
    }
  },
  methods: {

    saveSetting(setting, settingType, reload) {
      this.$emit('saveSet', '', setting, settingType, reload);
    },
   
  }
}
</script>