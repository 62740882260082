<template>
  <div>
    <div class="flex mb-4">
      <BackButton :href="`javascript:history.go(-1)`" :cssClass="'mr-4'" />
      <h4 class="text-xl">{{t('global.stats')}}:  {{ lectureStats.name }}</h4>
    </div>

    <div class="Form--Boxed mb-8">
      <p class="mb-2">{{t('pages.stats.lecture_started_by')}}:<b> {{ lectureStats.stats_tot_started_size }} {{t('global.users')}}</b></p>
      <p>{{t('pages.stats.lecture_completed_by')}}:<b> {{ lectureStats.stats_tot_completed_size }} {{t('global.users')}}</b></p>
      <span v-if="room" >
        <p>Start meeting: {{ room.meeting_info['start_datetime'] | moment("D MMM YYYY, k:mm")  }} </p>
        <p>End meeting: {{ room.meeting_info['end_datetime'] | moment("D MMM YYYY, k:mm")  }} </p>
      </span>
    </div>

    <ul class="List--Boxed mt-4">
      <li class="grid grid-cols-4	">
        <div class="UserList__Item__Name">
          <b>{{t('global.full_name')}}</b>
        </div>
        <div>
          <b>Email</b>
        </div>
        <div>
          <b>{{ t('global.completed.f') }}</b>
        </div>
          <div class="text-right">
            <b>{{t('global.actions')}}</b>
          </div>
      </li>

    <div v-if="loadingUsers" class="LoadingBox  LoadingBox--Small text-center">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <li v-else v-for="user in totUsers" :key="user.id" class="grid grid-cols-4">
      <div class="UserList__Item__Name">
        <span> {{ user.full_name }}   </span>
      </div>
      <div> <span>{{ user.email }}</span></div>

      <p>{{ user.completed ? '✔' : 'No' }} </p>

      <div class="text-right">
        <a 
          v-if="!selectedUsers.some( u => u === user.id )"
          @click="getActivities(user)" 
          class="Btn Btn--Primary Btn--Icon">
          <!-- <svg height="16" width="16" class="fill-current"> 
            <use xlink:href="#icon-arrow-right"></use>
          </svg> -->
          {{t('actions.show_details')}}
        </a>
        <a v-else
          @click="removeActivities(user)" 
          class="Btn Btn--Primary Btn--Icon">
          {{t('actions.hide_details')}}
        </a>
      </div>
      <template v-if="selectedUsers.some( u => u === user.id )">
        <ul class="List--Lined List--Lined--Thin mt-4 col-start-1 col-end-5" v-if="allActivities.length > 0" >
          <span >
            <h4 v-if="lectureStats.quiz">
              <span v-if="totAttempts.filter( a => { return a.user_id === user.id }).length > 0">
                tot attempts: {{ totAttempts.filter( a => { return a.user_id === user.id }).length }}
                /
                passed: {{ passed.filter( a => { return a.user_id === user.id }).length > 0 ? "true" : "false" }}
              </span> 
            </h4>
            <div class="mb-2" v-for="activity in allActivities" :key="activity.id" v-if="showActivity(activity.activity_type)">
              {{ activity.start_datetime | moment("DD/MM/YY, k:mm") }} - {{ activity.end_datetime | moment("DD/MM/YY, k:mm") }} - {{ totDuration(activity.duration) }} - {{ activityType(activity.activity_type)}}
            </div>
            <div v-if="room 
                      && allActivities.filter( a => { return a.user_id === user.id }).length > 0
                      && allActivities.filter( a => { return a.user_id === user.id }).slice(-1).pop().activity_type != 'user-left'" >
              {{ room.meeting_info['end_datetime'] | moment("DD/MM/YY, k:mm")  }} meeting-ended
          </div>
          </span>
        </ul> 
        <div class="col-start-1 col-end-5 py-1" v-else-if="loading">
          {{t('global.loading')}}
        </div >
        <div class="col-start-1 col-end-5 py-1" v-else>
          {{t('global.no_activities')}}
        </div >
     
       
        <div class="col-span-5 mt-2" v-if="addActivityOpen">
          <div class="grid grid-cols-4 gap-1">
            <div class="field">
              <label class="mb-1 inline-block font-bold" for="start_datetiem">{{t('user_activity.start_datetime')}}</label>
              <datetime 
                :placeholder="t('actions.select_date')"
                type="datetime"
                v-model="activity.start_datetime"
                class="">
              </datetime>
            </div>
            <div class="field">
              <label class="mb-1 inline-block font-bold" for="end_datetime">{{t('user_activity.end_datetime')}}</label>
              <datetime 
                :placeholder="t('actions.select_date')"
                type="datetime"
                v-model="activity.end_datetime"
                class="">
              </datetime>
            </div>
            <div class="field">
              <label class="mb-1  inline-block font-bold" for="duration">{{t('user_activity.duration')}} (Sec)</label>
              <input id="duration"  v-model="activity.duration" type="text" />
            </div>
            <div class="field">
              <label class="mb-1 inline-block font-bold" for="activity_type">{{t('user_activity.activity_type')}}</label>
              <select v-model="activity.activity_type" id="activity_type" class="Select mb-4">
                  <option value="">{{t('helpers.select.prompt')}}</option>
                  <option  :value="'live_session'">{{t('user_activity.live_session')}} </option>
                  <option  :value="'in_person_session'">{{t('user_activity.in_person_session')}}</option>
                  <option  :value="'on_demand_session'">{{t('user_activity.on_demand_session')}}</option>
                  <option  :value="'scorm_session'">{{t('user_activity.scorm_session')}}</option>
                </select>
            </div>
          </div>
         
          <button @click="addActivity(activity, user )" class="Btn Btn--Save">
            {{t('actions.save')}}
          </button>
          <button @click="addActivityOpen = false" class="Btn Btn--Delete">
            {{t('actions.close')}}
          </button>
        </div>
        
        
        <div class="col-start-1 col-end-5 text-right">
          <button  v-if="!addActivityOpen" @click="addActivityOpen = true" class="Btn Btn--Save Btn--Icon">
              Aggiungi attività
          </button>
          <button  v-if="!user.completed"  @click="completeLecture(user)" class="Btn Btn--Save Btn--Icon">
            {{t('actions.mark_as_completed')}}
          </button>
         
        </div>
      
      </template>
  
       
    </li>


    </ul>
    <div class="text-center mt-2">
      <paginate
        v-model="page"
        :page-count="pagy.pages"
        :click-handler="pageCallback"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'PaginationList'">
      </paginate>
    </div>

   

  </div>
</template>

<script>
import api from '../../../api'
import completedLecture from '../../../api/completedLecture';
import BackButton from '../../commons/backButton'
import { global as EventBus } from '../../../js/utils/eventBus'; 


export default {
  props: {
    lectureStats: Object,
    room: Object,
    course: Object
  },
  components: {
    BackButton
  },
  data(){
    return {
      allActivities: [],
      selectedUsers: [],
      loading: false,
      loadingUsers: false,
      enrolls: [],
      page: 1,
      search: '',
      sortBy: 'desc',
      pagy: [],
      addActivityOpen: false,
      activity: {
        activity_type: '',
        start_datetime: '',
        end_datetime: '',
        duration: 0,
        lecture_id: this.lectureStats.id
      }
    }
  },

  mounted() {
    this.getEnrolls()
  },
  computed: {
    totAttempts(){
      return this.allActivities.filter(activity => activity.activity_type.includes('started-quiz-attempt'))
    },
    passed(){
      return this.allActivities.filter(activity => activity.activity_type.includes('quiz-completed-PASS'))
    },
    totUsers(){
      return this.enrolls.map( enroll => {    
        let completed = this.lectureStats.stats_tot_completed.some( c => {          
          return c.user.id === enroll.user.id
        })
        return {
          id: enroll.user.id,
          completed: completed,
          full_name: `${enroll.user.first_name + ' ' + enroll.user.last_name}`,
          email: enroll.user.email
        }
      }).sort(function(a,b){return b.completed-a.completed});
    },
  },
  methods: {
    getEnrolls(){
      this.loadingUsers = true;
      api.Course.getEnrollStats(this.course, this.page, this.sortBy, true, this.search)
      .then( res => {
        console.log(res)
        // self.enrolls = [...self.enrolls, ...res]
        this.enrolls = res.enrolls
        this.pagy = res.pagy
        this.loadingUsers = false;
      })
    },
    pageCallback(pageNum){
      console.log(pageNum)
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getEnrolls()
    },

    async getActivities(user){
      this.loading = true
      try {
        const res = await api.UserActivity.getUserActivities( this.lectureStats, user )
        this.loading = false 
        this.allActivities = res
        this.selectedUsers.push(user.id)
      } catch (error) {
        console.error(error)
      }
      
    },
    removeActivities(user) {
      this.selectedUsers = this.selectedUsers.filter(( obj ) => {
        return obj !== user.id;
      });
      this.allActivities = this.allActivities.filter(( obj ) => {
        return obj.user_id !== user.id;
      });
    },
    activityType(type) {
      switch (type) {
        case "live_session":
          return 'Live session'
        case "on_demand_session":
          return 'On demand session'
        case "scorm_session":
          return 'Scorm session'
        case "in_person_session":
          return 'In person session'
      }
    },
    showActivity(type) {
      return (type == "live_session" || type == "on_demand_session" || type == "scorm_session" || type == "in_person_session")
    },

    totDuration(totSecs) {
      let totalSeconds = totSecs || 0
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },

    completeLecture(user) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.CompletedLecture.markAsCompleted( this.lectureStats.id, user.id )
      .then((res) => { 
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target })
        setTimeout(() => {
          location.reload()
        }, 800); 
      }).catch(err => {
        EventBus.emit('loader:local:end', { elem: target })
        this.$toastr.e(this.t('actions.error'));
      })
    },

    async addActivity(activity, user){
      if (!this.activity.start_datetime || !this.activity.end_datetime || !this.activity.duration || !this.activity.activity_type) {
        alert(this.t('errors.messages.all_fields_required'));
        return false;
      }
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      activity.user_id = user.id
      try {
        const ua = await api.UserActivity.create(activity)
        this.allActivities.push(res)
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target })
        const res = await this.getActivities(user)

      } catch (error) {
        EventBus.emit('loader:local:end', { elem: target })
        this.$toastr.e(this.t('actions.error'));
      }
      

    }

  }
}
</script>