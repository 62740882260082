<template>
  <div :class="{LecturePage: true, open: isOpen && !hideSideBar}">
    <div class="LecturePage__SideBar">
      <sidebar 
        :currentLecture="lecture" 
        :lectures="course.path ? course.related_courses: lectures" 
        :course="course" 
        :logo="logo"
        :enrolledPath="enrolledPath" />
      <button v-if="!hideSideBar" @click="isOpen = !isOpen" class="LecturePage__Toggle">
        <svg height="16" width="16" class="fill-current"> 
          <use xlink:href="#icon-menu"></use>
        </svg>
      </button>
    </div>
    <div class="LecturePage__Main">
      <div class="LecturePage__Main__Header flex">
        <BackButton v-if="hideSideBar" :href="`/courses/${course.id}/page`" :cssClass="'mr-4'" />
        <h1 class="sm:text-xl ml-8 leading-tight">{{ lecture.name }}</h1>
      </div>
      <div class="LecturePage__Main__Body">


        <div v-if="edflexContent && !scormContent">
          <div class="Box Box--Clear text-center px-8">
           <a :href="edflexContent.content_code" class="Btn Btn--Save">{{ t('actions.start') }}</a>
          </div>
        </div>


        <div v-if="spotifyContent">
          <iframe style="border-radius:12px" :src="`https://open.spotify.com/embed/episode/${spotifyContent.content_code}?utm_source=generator`" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>

        <div v-if="googleFormContent">
          <div class="Box Box--Clear LectureBox--FullHeight">
            <iframe 
              :src="`https://docs.google.com/forms/d/${googleFormContent.content_code}/viewform?embedded=true`" 
              width="100%" 
              height="100%"
              frameborder="0" 
              marginheight="0" 
              marginwidth="0">
              Caricamento…
            </iframe>
          </div>
        </div>

        <quiz v-if="quiz && vimeoContents.length === 0 && pdfContents.length === 0 && ytContents.length === 0"  :lectureQuiz="quiz" :course="course" :next="next" />

        <youtube v-if="ytContents.length > 0"
          :videoId="ytContents[0].content_code"
          :lecture="lecture" 
          :blockForward="blockForward && !lecture.completed" 
          :course="course"  
          :next="next" 
          :user="currentUser"
          :videoIndex="index"
          :quiz="quiz"
          :videoProgress="videoProgress"
          :nextCourse="nextCourse"
          :prizesActive="prizesActive"
          />

        <div class="Tabs" v-if="vimeoContents.length > 1">
          <div class="Tabs__Nav mb-4">
            <button @click="selectedVideo = index" v-for="(content, index) in vimeoContents" :key="content.id" :class="{selected: selectedVideo ===  index}">Parte {{index+1}}</button>
          </div>
        </div>
        <div v-for="(content, index) in vimeoContents" :key="content.id" class="VideoContainer">
          <vimeo v-if="selectedVideo === index" 
                :videoId="content.content_code" 
                :lecture="lecture" 
                :blockForward="blockForward && !lecture.completed" 
                :course="course"  
                :next="next" 
                :keyId="content.id"
                :multipleVideos="vimeoContents.length > 1"
                :user="currentUser"
                :videoIndex="index"
                :quiz="quiz"
                :videoProgress="videoProgress"
                :autoplay="!autocertification" 
                :nextCourse="nextCourse"
                :isTaxLegal="isTaxLegal"
                :prizesActive="prizesActive"
          />
        </div>

        <div v-for="content in vimeoLiveContents" :key="content.id" class="VideoContainer">

          <div v-if="!content.content_code" class="Box Box--Clear">
            <countdown :time="new Date(lecture.start_date).getTime() - new Date().getTime()">
              <template slot-scope="props">
                <div class="CountDown">
                <div id="timer">
                  <div class="days"> <div class="numbers">{{ props.days }}</div>D</div> 
                  <div class="hours"> <div class="numbers">{{ props.hours }}</div>H</div> 
                  <div class="minutes"><div class="numbers"> {{ props.minutes }}</div>M</div> 
                  <div class="seconds"><div class="numbers">{{ props.seconds }}</div>S</div> </div>
                  <h3 class="text-2xl mt-8">LIVE: {{lecture.start_date | moment("D/M/YY k :mm") }}</h3>
                </div>
              </template>
            </countdown>
          </div>

          <vimeo  
            v-if="content.content_code" 
            :videoId="content.content_code" 
            :lecture="lecture" 
            :blockForward="blockForward && !lecture.completed" 
            :course="course"  
            :next="next" 
            :videoProgress="videoProgress"
            :autoplay="!autocertification"
            :isTaxLegal="isTaxLegal"
            :prizesActive="prizesActive"
          />
        </div>

        <div v-if="bbbContent && vimeoContents.length === 0 && (!zoomUrlContent || (zoomUrlContent && ( zoomUrlContent.content_code === '' || !zoomUrlContent.content_code )) ) ">
          <bbbContent :lecture="lecture" :room="room" :recordings="recordings" />
        </div>

        <div v-if="zoomUrlContent && zoomUrlContent.content_code != ''">
          <zoomUrlContent :lecture="lecture" :content="zoomUrlContent" />
        </div>

        <div v-if="(zoomContent || teamsContent || attendanceContent) && vimeoContents.length === 0">
          <liveContent :lecture="lecture" :content="zoomContent || teamsContent || attendanceContent" :course="course" />
        </div>

        <div v-for="content in pdfContents" :key="content.id">
          <pdfViewer :content="content" :lecture="lecture" :quiz="quiz" :course="course"  :next="next"   />
        </div>


        <div v-if="scormContent">
          <div v-if="scormError" class="Box Box--Clear"><p class="text-danger">Ops! Si è verificato un errore nel caricamento del corso.</p></div>
          <scormContent :lecture="lecture" :content="scormContent" :launchLink="launchLink" />
        </div>
      


        <div v-if="lecture.description || attachmentContents.length > 0 || (commentsEnabled )" class="LecturePage__Bottom pb-8">
          <div v-if="lecture.description || attachmentContents.length > 0 || commentsEnabled" class="Tabs mt-4">
            <div class="Tabs__Nav mb-4">
              <button v-if="lecture.description" @click="selectTab('description')" :class="{selected: selectedTab === 'description'}">{{t('pages.lecture.notes')}}</button>
              <button v-if="attachmentContents.length > 0" @click="selectTab('attachments')" :class="{selected: selectedTab === 'attachments'}">{{t('pages.lecture.materials')}}</button>
              <button v-if="commentsEnabled" @click="selectTab('comments')" :class="{selected: selectedTab === 'comments'}">{{t('pages.lecture.comments')}}</button>
            </div>
          </div>

          <description :html="lecture.description" v-if="selectedTab === 'description'" class="mt-4"  ref="notes"/>

          <div v-if="selectedTab === 'attachments'">
            <div v-for="content in attachmentContents" :key="content.id" class="LecturePage__Attachments">
              <div class="mb-2">
                <a :href="content.url" target="blank" class="Btn--Text break-words">{{ content.filename }}</a>
              </div>
            </div>
          </div>

          <forum v-if="commentsEnabled && selectedTab === 'comments'" :currentUser="currentUser" :lecture="lecture" :inLecture="true" :course="course" />

        </div>
      </div>
    </div>


    <modal name="autocertification-modal" height="auto" class="v--modal--prevent-close">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">Autocertificazione:</h2>
      </div>
      <div class="v--modal--body body--users p-4">
        <!-- <p v-if="lecture.quiz">Il sottoscritto dichiara di rispondere ai test in prima persona durante l’orario di lavoro.</p> -->
        <p>Il sottoscritto dichiara di partecipare alla presente lezione in prima persona durante l’orario di lavoro.</p>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="closeModalAndPlay">Accetta</button>
      </div>
    </modal>

    
  </div>
</template>

<script>
import api from '../../api'
import sidebar from '../commons/sidebar'
import description from '../commons/description'
import quiz from '../quiz/show'
import { vueVimeoPlayer } from 'vue-vimeo-player'
import pdfViewer from '../commons/pdfViewer'
import comments from '../commons/comments'
import bbbContent from '../lecture/bbbContent'
import liveContent from '../lecture/liveContent'
import zoomUrlContent from '../lecture/zoomUrlContent'
import scormContent from '../lecture/scormContent'
import forum from '../course/forum'
import vimeo from './vimeo'
import BackButton from '../commons/backButton'
import youtube from './youtube'
import { global as EventBus } from '../../js/utils/eventBus';




export default {
  name: 'LectureShow',

  components: { 
    bbbContent,
    liveContent,
    zoomUrlContent,
    scormContent,
    vueVimeoPlayer,
    pdfViewer,
    sidebar,
    description,
    quiz,
    forum,
    vimeo,
    BackButton,
    youtube
  },

  data() {
    return {
      lectures: [],
      isOpen: window.innerWidth < 768 ? false : true,
      resp: Object,
      selectedTab: null,
      selectedVideo: 0
    }
  },

  props: {
    currentUser: Object,
    lecture: Object,
    room: Object,
    recordings: Object,
    course: Object,
    quiz: Object,
    messages: Array,
    logo: String,
    next: String,
    tenant_name: String,
    commentsEnabled: Boolean,
    blockForward: Boolean,
    launchLink: String,
    hideSideBar: Boolean,
    scormError: Boolean,
    videoProgress: Number,
    autocertification: Boolean,
    nextCourse: Object,
    enrolledPath: Number,
    isTaxLegal: Boolean,
    prizesActive: Boolean,
  },

  channels: {
    LecturesChannel: {
      connected() {
        console.log("connected");
      },
      rejected() {
        console.log("rejected");
      },
      received(data) {
        console.log(data)
      },
      disconnected() {
        console.log("disconnected");
      }
    }
  },

  computed: {

    contents() {
      return this.lecture.contents
    },
    vimeoContents() {
      return this.lecture.contents.filter((content) => {
        return content.content_type === 'vimeo'
      })
    },
    ytContents() {
      return this.lecture.contents.filter((content) => {
        return content.content_type === 'youtube'
      })
    },
    vimeoLiveContents() {
      return this.lecture.contents.filter((content) => {
        return content.content_type === 'vimeo_live'
      })
    },
    bbbContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'bbb'
      })[0]
    },
    zoomContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'zoom'
      })[0]
    },
    teamsContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'teams'
      })[0]
    },
    attendanceContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'attendance'
      })[0]
    },
    zoomUrlContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'zoom_url'
      })[0]
    },
    pdfContents(){
      return this.lecture.contents.filter( (content) => {
        return (content.content_type === 'pdf'  ) 
      } )
    },
    attachmentContents() {
      return this.lecture.contents.filter((content) => {
        return ( content.content_type === 'attachment' )
      })
    },
    scormContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'scorm'
      })[0]
    },

    edflexContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'edflex'
      })[0]
    },

    spotifyContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'spotify'
      })[0]
    },
    pastLive() {
      const oneday = 60 * 60 * 24 * 1000
      return new Date().getTime() - new Date(this.lecture.start_date).getTime() > oneday
    },
    googleFormContent() {
      return this.contents.filter((content) => {
        return content.content_type === 'google_form';
      })[0];
    },
  },

  mounted() {
    console.log('Component mounted.')
    console.log('Lecture Contents:', this.lecture.contents);
    this.lectures = this.course.lectures
    this.$cable.subscribe({
      channel: 'LecturesChannel',
      tenant_name: this.tenant_name,
      current_user: this.currentUser.id,
      lecture_id: this.lecture.id
    });
    this.getSelectedTab();
    this.checkIfTextOnly();
    if (this.autocertification) {
      this.$modal.show('autocertification-modal',  {height: 'auto', clickToClose: false } )
    }


    
  },

  methods: {

    closeModalAndPlay() {
      this.$modal.hide('autocertification-modal', {
        adaptive: true,
        maxWidth: '600px'
      })
      EventBus.emit('video:play')
    },

    async completedLecture(){
      try {
        const resp = await api.CompletedLecture.create(this.lecture.id)
        this.resp = resp
      } catch (error) {
        console.log(error)
      }
        
    },

    getSelectedTab(){
      if (this.lecture.description) {
        this.selectedTab = 'description'
      } else if (this.attachmentContents.length > 0 ) {
        this.selectedTab = 'attachments'
      } else {
        this.selectedTab = 'comments'
      }

    },
    selectTab(selection){
      this.selectedTab = selection
    },

    checkIfTextOnly (){
      // if lecture is not live and there is no video or pdf lecture has to be completed
      let self = this
      if (this.lecture.live || this.quiz ) {
        console.log('live or quiz, do not complete')
      } else {
        if  ( self.vimeoContents.length === 0 && self.pdfContents.length === 0 ) {
        setTimeout( e => { //todo: capire perche this.$refs.notes.$el viene renderizzato dopo
          if (this.$refs.notes.$el.getBoundingClientRect().bottom < window.innerHeight ) {
            api.CompletedLecture.create(self.lecture.id)
            .then((res) => {
              self.resp = res
              EventBus.emit('lecture:completed', {lectureId: this.next?.id})
            })
          } else {
            window.onscroll = function(ev) {
              if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                console.log('scrolled to bottom')
                api.CompletedLecture.create(self.lecture.id)
                .then((res) => {
                  self.resp = res
                  EventBus.emit('lecture:completed', {lectureId: this.next?.id})
                })
              }     
            }
          }
        }, 500)
        
      };
      }
      
    }

  }
}

</script>



