<template>
  <div>
    <div>
      <div class="Form--Boxed mb-4">
        <h3 class="mb-4">{{t('pages.gamification.prizes.title')}}</h3>
        <p></p>
        <a  class="Btn Btn--Save" href="/admin/gamification/prizes">{{t('settings.go_to_prizes')}}</a>
      </div>
    </div>
    <div class="Form--Boxed mb-4">
      <h3 class="mb-4">{{t('pages.gamification.requests.title')}}</h3>
      <p></p>
      <p v-if="requests && requests.length == 0">
        {{t('pages.gamification.requests.no_records')}}
      </p>
      <table class="Table" v-if="requests && requests.length > 0">
        <thead>
          <tr>
            <th class="text-left">{{t('global.user.s')}}</th>
            <th class="text-left">{{t('pages.prizes.name')}}</th>
            <th class="text-left">{{t('pages.prizes.points')}}</th>
            <th class="text-left">{{t('global.date')}}</th>
            <th class="text-left">{{t('global.status')}}</th>
            <th class="text-right"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="request in requests" :key="request.id">
            <td>{{request.user_gamification.user.full_name}}</td>
            <td>{{request.prize.name}}</td>
            <td>{{request.prize.points}}</td>
            <td>{{request.created_at | moment("DD/MM/YY, k:mm")}}</td>
            <td>{{t(`pages.gamification.requests.status_${request.status}`)}}</td>
            <td class="text-right">
              <button class="Btn Btn--Edit Btn--Icon mr-1" @click="setStatus($event,request,1)" v-if="request.status == 0">
                <svg height="16" width="16" class="fill-current">
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
              </button>
              <button class="Btn Btn--Delete Btn--Icon" @click="setStatus($event,request,2)" v-if="request.status == 0">
                <svg height="16" width="16" class="fill-current">
                  <use xlink:href="#icon-close"></use>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- TODO: spostare in pagina premi, quando verra fatta in vue -->
    <div class="Form--Boxed mb-4">
      <label for="">{{t('settings.activate_prizes')}}</label>
      <div class="flex items-start field--checkbox mb-4">
        <input type="checkbox" v-model="booleanPrizesActive" id="prizes_active" class="mt-2">  
        <label class="ml-2 normal" for="prizes_active"> {{t('settings.activate_prizes_hint')}}</label>
      </div>
      <button @click="saveSetting(settings.prizes_active, 'prizes_active', true)" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
    </div>

  <div>
    <div class="Form--Boxed mb-4">
      <h3 class="mb-4">{{t('pages.gamification.badges.title')}}</h3>
      <p></p>
      <a  class="Btn Btn--Save mb-8" href="/admin/gamification/badges">{{t('settings.go_to_badges')}}</a>
      <div class="flex items-start field--checkbox mb-4">
        <input type="checkbox" v-model="booleanBadgesActive" id="badges_active" class="mt-2">  
        <label class="ml-2 normal" for="badges_active"> {{t('settings.activate_badges_hint')}}</label>
      </div>
      <button @click="saveSetting(settings.badges_active, 'badges_active', true)" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
    </div>
  </div>
    
  <div>
    <div class="Form--Boxed mb-4">
      <h3 class="mb-4">{{t('pages.gamification.leaderboards.title.p')}}</h3>
      <p></p>
      <a class="Btn Btn--Save mb-8" href="/admin/gamification/leaderboards">{{t('settings.go_to_leaderboards')}}</a>
    
      <div class="flex items-start field--checkbox mb-4">
        <input type="checkbox" v-model="booleanLeaderboardsActive" id="leaderboards_active" class="mt-2">  
        <label class="ml-2 normal" for="leaderboards_active"> {{t('settings.activate_leaderboards_hint')}}</label>
      </div>
      <button @click="saveSetting(settings.leaderboards_active, 'leaderboards_active', true)" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
    </div>
  </div>
</div>
</template>

<script>
import api from '../../../api'
import { global as EventBus } from '../../../js/utils/eventBus'

export default {
  name: 'Gamifications',
  props: {
    settings: Object
  },
  data() {
    return {
      requests: null
    }
  },

  computed: {
    booleanPrizesActive: {
      get() {
        return this.settings.prizes_active.value === 'true';
      },
      set(value) {
        this.settings.prizes_active.value = value ? 'true' : 'false';
      }
    },
    booleanBadgesActive: {
      get() {
        return this.settings.badges_active.value === 'true';
      },
      set(value) {
        this.settings.badges_active.value = value ? 'true' : 'false';
      }
    },
    booleanLeaderboardsActive: {
      get() {
        return this.settings.leaderboards_active.value === 'true';
      },
      set(value) {
        this.settings.leaderboards_active.value = value ? 'true' : 'false';
      }
    }
  },
  methods: {
    saveSetting(setting, settingType, reload, apply) {
      this.$emit('saveSet', '', setting, settingType, reload, apply);
    },

    getPrizeRequests() {
      api.Prize.requestList()
        .then(res => {
          this.requests = res;
          console.log(this.requests);
        })
        .catch(err => {
        })
    },
    setStatus(event, request, value) {
      const elem = event.currentTarget
      EventBus.emit('loader:local:start', { elem })
      if (value === 1 && request.prize.stock <= 0) {
        this.$toastr.e(this.t('pages.gamification.prizes.stock_exceeded'));
        EventBus.emit('loader:local:end', { elem });
        return;
      }

      api.Prize.setRequestStatus(request.id, value)
        .then(res => {
          this.requests = this.requests.map((r) => {
            if(r.id = res.id) {
              return res;
            }
            return r
          });
          if (value === 1) {
            const prizeId = request.prize_id;
            const updatedStock = request.prize.stock - 1;
          if (updatedStock >= 0) {
            api.Prize.updateStock(prizeId, { stock: updatedStock })
              .then(() => {
                console.log(`Stock aggiornato per il premio con ID ${prizeId}`);
              })
              .catch(err => {
                console.error(`Errore nell'aggiornamento dello stock del premio con ID ${prizeId}`, err);
              });
          } else {
            console.error(`Stock insufficiente per il premio con ID ${prizeId}.`);
          }
        }
        EventBus.emit('loader:local:end', { elem })
      })
      .catch(err => {
        EventBus.emit('loader:local:end', { elem })
      })
    }
  },
  mounted() {
    this.getPrizeRequests()
  },
}
</script>