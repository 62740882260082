<template>
  <div>

    <div class="flex mb-4">
      <BackButton :href="`../${course.id}/edit`" :cssClass="'mr-4'" />
      <h2 class="text-xl mb-2">{{t('global.stats')}}: {{ course.name }}</h2>
    </div>

    <div class="flex justify-between items-center">
      <div class="Tabs">
        <div class="Tabs__Nav mb-4">
          <button @click="selectTab('users')" :class="{selected: selectedTab ===  'users'}">{{t('global.users')}}</button>
          <button @click="selectTab('lectures')" :class="{selected: selectedTab ===  'lectures'}">{{t(`global.${course.path ? 'courses.p' : 'lectures.p'}`)}}</button>
        </div>
      </div>

      <button v-if="reportsEnabled" class="Btn Btn--Save" :disabled="exportingStats" @click="exportStats">{{t('actions.export_stats')}}</button>
    
    </div>

    <!-- <div class="grid sm:grid-cols-5 gap-4">
      <div v-for="(stat, index) in stats" :key="index" class="Box Box--Clear mb-4">
        <h2>{{ stat.value }}</h2>
        <p>{{ stat.label }}</p>
      </div>
    </div> -->

    <p v-if="exportingStats" class="text-center mb-2">
      {{t('pages.stats.export_in_progress')}}
    </p>
    
    <div class="Box Box--Clear mb-8">
      <div v-if="totStatsError" class="text-center p-4 text-danger">
        <strong>{{ totStatsError }}</strong>
      </div>
      <div v-else-if="loading" class="LoadingBox">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
      <div v-else class="grid sm:grid-cols-2 gap-4">
        <pie-chart v-if="totEnrolls > 0" :donut="true" :data="chartData" legend="bottom" ></pie-chart>
        <div v-else class="text-center">
          <img src="../../../images/illustrations/stats.png" class="max-w-sm	ml-auto mr-auto w-full"/>
          <p class="text-lg text-grayScale-middle">{{t('pages.stats.no_stats_course')}}</p>
        </div>
        <div>
          <p v-for="(stat, index) in stats" 
            :key="index" 
            :class="['font-bold flex items-center', {'mb-4': index > 0 || course.path}, {'hidden': index === 1 && course.path}]">
            <span class="mr-2" >{{ t(stat.label) }}: </span>
            <span class="text-xl" >{{ stat.value }}  <span v-if="stat.percentageOfTotal">({{stat.percentageOfTotal}}%)</span> </span>
          </p>
        </div>
      </div>
    </div>

    <enrollsList v-show="selectedTab === 'users'"  :course="course" :fiscalCodeLogin="fiscalCodeLogin" />    

    <lectureStatsList v-show="selectedTab === 'lectures'" :course="course" :totEnrolls="totEnrolls" />

  </div>
</template>

<script>
import BackButton from '../../commons/backButton'
import lectureStatsList from './lectureStatsList'
import enrollsList from '../../commons/enrollsList'
import api from '../../../api'

export default {
  props: {
    course: Object,
    currentUser: Object,
    reportsEnabled: Boolean,
    fiscalCodeLogin: Boolean
  },
  data(){
    return {
      selectedTab: 'users',
      exportingStats: false,
      totStats: {},
      totStatsError: '',
      loading: false
    }
  },
  components: {
    enrollsList,
    lectureStatsList,
    BackButton
  },

  mounted() {
    api.Course.getCourseStats(this.course)
      .then( res => {
        this.course = res;
     })

     this.loading = true;
     api.Course.getGlobalCourseStats(this.course)
      .then( res => {
        console.log(res)
        // self.enrolls = [...self.enrolls, ...res]
        this.totStats = res.tot_stats
        this.loading = false;
      }).catch( err => {
        console.log(err)
        this.totStatsError = err
        this.loading = false;
      })
  },


  computed: {
    totEnrolls(){
      console.log(this.totStats)
      return this.totStats.tot_enrolls
    },
    totNotStarted(){
      return this.totStats.tot_not_started
    },
    totInProgress(){
      return this.totStats.tot_in_progress
    },
    totCompleted(){
      return this.totStats.tot_completed
    },

    stats(){
      return [
          { label: 'pages.stats.total_users', value: this.totEnrolls },
          { label: 'pages.stats.not_started', value: this.totNotStarted, percentageOfTotal: this.totNotStarted === 0 ? '' : ((this.totNotStarted / this.totEnrolls)*100).toFixed() },
          { label: `pages.stats.in_progress_${this.course.path ? 'path' : 'course'}`, value: this.totInProgress, percentageOfTotal: this.totNotStarted === 0 ? '' : ((this.totInProgress / this.totEnrolls)*100).toFixed() },
          { label: `pages.stats.completed_${this.course.path ? 'path' : 'course'}`, value: this.totCompleted, percentageOfTotal: this.totNotStarted === 0 ? '' : ((this.totCompleted / this.totEnrolls)*100).toFixed() }
      ]
    },
    chartData(){
      return [['Not started', this.totNotStarted], ['In Progress', this.totInProgress], ['Completed', this.totCompleted]]
    }
  },
  methods: {
    selectTab(selection){
      this.selectedTab = selection
      if (selection === 'users') {
        // this.getAllUsers(this.users)
      } else if (selection === 'lectures') { 
        // this.getAllUsers(this.admins)
      } 
    },
    exportStats(){
      api.Course.exportCourseStats(this.course)
      .then( () => {
        this.exportingStats = true
      })
    },


  }
}
</script>