<template>
  <div>
    <div class="Form--Boxed mb-4">
      <div v-for="setting in settings.basicInfos" :key="setting.id">
        <div class="field" >
          <label>{{ t(`settings.${setting.name}`)}}</label>
          <input type="text" v-model="setting.value" />
        </div>
        <button @click="saveSetting(setting, 'basicInfo')" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
      </div>
    </div>
    <div class="Form--Boxed mb-4">
      <div class="TripleUpload sm:grid sm:grid-cols-3 gap-2">
        <settingWithImage :setting="settings.logo" settingClass="academySetting" settingType="logo" name="Logo" />
        <settingWithImage :setting="settings.logo_mobile" settingClass="academySetting" settingType="logo_mobile" name="Logo mobile" />
        <settingWithImage :setting="settings.favicon" settingClass="academySetting" settingType="favicon" name="Favicon" />
      </div>
    </div>
    <div class="Form--Boxed mb-4">
      <div v-for="style in stylesWithoutHsl" :key="style.id" class="relative">  
        <label>{{ t(`settings.colors.${style.name}`) }}</label> 
        <div class="flex items-center mb-4">
          <span class="current-color mr-2" :style="'background-color: ' + `${style.value.hex ? style.value.hex : style.value }`" @click="togglePicker(style.id, style.value.hex)"></span>
          <input v-model="style.value.hex" />
          <div class="chrome-wrapper">
            <div class="flex justify-between pb-2">
              <button class="Btn Btn--Text" v-if="pickerToggled === style.id" @click="pickerToggled = ''">{{t('actions.close')}}</button>
              <button class="Btn Btn--Text" v-if="pickerToggled === style.id" @click="resetColor(style)">{{t('actions.reset')}}</button>
            </div>
            <chrome-picker v-model="style.value" v-if="pickerToggled === style.id" />
          </div>
          <button @click="updateStyleValue(style)" class="Btn Btn--Save">{{t('actions.save')}}</button>
        </div>
        
      </div>
    </div>



    <div class="Form--Boxed mb-4" v-if="settings.css_custom">
      <div class="field">
        <label for="css-custom">{{t('global.css_custom')}}</label>
        <textarea v-model="settings.css_custom.value" name="css-custom" id="css-custom" class="Textarea Textarea--Half" rows="5"></textarea>
      </div>
      <button class="Btn Btn--Save mb-4" @click="saveSetting(settings.css_custom, 'css_custom', true)">{{t('actions.save')}}</button>
    </div>
    

    <div class="Form--Boxed mb-4" v-if="settings.font_custom">
      <div class="field">
        <label for="font_custom">{{t('global.font_custom')}}</label>
        <textarea v-model="settings.font_custom.value" name="font_custom" id="font_custom" class="Textarea Textarea--Half" rows="5"></textarea>
      </div>
      <button @click="saveSetting(settings.font_custom, 'font_custom', true)" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
    </div>

  
  </div>

</template>

<script>
import settingWithImage from '../../commons/settingWithImage'
import { Chrome } from 'vue-color'
import { global as EventBus } from '../../../js/utils/eventBus'; 
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Branding',
  props: {
   settings: Object,
   languages: Array,
  },
  data() {
    return {
      pickerToggled: '',
      currentColor: '',
      selectedLang: 'it',
    }
  },

  mounted() {
   console.log( this.settings.home_translations)
  },
  
  components: {
    settingWithImage,
    'chrome-picker': Chrome
  },
  computed: {
    ...mapGetters({
      styles: "academyStyles"
    }),


    stylesWithoutHsl() {
      //remove the prop hsl from the styles items
      return this.styles.map(style => {
        delete style.value.hsl
        return style
      })
    },
  },
  methods: {

    ...mapActions({
      getStyles: 'getStyles',
      updateAcademySetting: 'updateAcademySetting',
      updateStyle: 'updateStyle'
    }), 
    saveSetting(setting, settingType, reload) {
      this.$emit('saveSet', '', setting, settingType, reload);
    },
    togglePicker(style_id, color){
      this.pickerToggled = style_id
      this.currentColor = color
      console.log(color, style_id)
    },
    updateStyleValue(style) {
      const target = event.currentTarget ;
      EventBus.emit('loader:local:start', { elem: target })
      api.Style.update(style)
      .then( res => {
        this.updateStyle(res)
        this.pickerToggled = ''
        location.reload()
      }).catch(err => {
        EventBus.emit('loader:local:end', { elem: target })
      })
    },

    resetColor(style) {
      style.value.hex = this.currentColor
      this.pickerToggled = ''
    },
  }
}
</script>


<style  scoped>
  .current-color {
    display: inline-block;
    width: 64px;
    height: 32px;
    border-radius: 7px;
    background-color: #000;
    cursor: pointer;
  }


  .chrome-wrapper {
    position: absolute;
    top: 0;
    left: 400px;
    background: white;
    text-align: center;
    z-index: 10;
  }


</style>