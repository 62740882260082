<template>
  <div class="LectureList__Wrapper mt-4">
      <draggable 
          :list="staticArr" 
          ghostClass="sortable-ghost"
          tag="ul"
          :sort="canEdit"
          :class="['LectureList Draggable bg-white rounded overflow-hidden List-SingleBoxes', {loading: loading}]"
          @start="onStart"
          @end="updateRowOrder">
        <li v-for="lecture in staticArr" 
            :key="lecture.id" 
            class="LectureItem" 
            :id="lecture.id" 
            :class="[(lecture.header ? 'SectionItem bg-grayScale-light' : ''), 'flex items-center justify-between mb-1 p-1']"
            :data-id="lecture.position" >
          <span class="LectureItem__Position text-grayScale-middle text-sm mr-2">{{lecture.position}}</span>
          <span v-if="!lecture.header" class="Tag">{{t(`global.${type(lecture)}`)}}</span>
          <span :class="[lecture.header ? 'font-bold' : '', 'flex-1']">{{ lecture.name }}</span>
          <span v-if="lecture.live" class="mr-2">{{lecture.start_date | moment("D MMM YYYY, k:mm") }}</span>
          <span  v-if="isLacerbaAdmin" class="LectureItem__Position text-grayScale-middle text-sm mr-2">{{lecture.external_lecture_id}}</span>
          <div class="LectureItem__Actions text-white" v-if="canEdit">
           <span v-if="!lecture.header && !lecture.live" class="flex-1 text-grayScale-dark mr-2"> {{duration(lecture.duration)}}</span>
            <a :href="`lectures/${lecture.id}`" class="Btn Btn--Edit Btn--Icon">
            <svg height="16" width="16" class="fill-current"> 
              <use xlink:href="#icon-edit-pencil"></use>
            </svg>
            </a>
            <button @click="removeLecture(lecture)" class="Btn Btn--Delete Btn--Icon">
               <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
          </div>
        </li>
        <div class="lds-ellipsis lds-ellipsis--colored" v-if="loading" ><div></div><div></div><div></div><div></div></div>
       </draggable>

       <modal name="error-modal" height="auto">
        <div class="v--modal--body body--users p-4 text-center text-danger">
          <p class="pt-8">Ops! Sembra ci sia stato un errore.</p>
        </div>
       </modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import api from '../../../api'
import { global as EventBus } from '../../../js/utils/eventBus'; 



export default {
  name: 'LectureList',

  props: {
    course: Object,
    isLacerbaAdmin: Boolean
  },

  data() {
    return {
      loading: false,
      staticArr: []
    }
  },

  components: {
    draggable
  },

  computed: {

    ...mapGetters({
      lectures: "lectures"
    }),

    canEdit(){
      return !this.course.ext_academy || this.isLacerbaAdmin
    },

  },

  watch: {
    lectures (newVal, oldVal) {
      this.staticArr = newVal
    }
  },

  mounted() {

    this.staticArr = [...this.course.lectures]

    this.getLectures(this.course.lectures)

    EventBus.on('lecture:added', el => {
      setTimeout( () => {
        document.getElementById(el.target.id).scrollIntoView({
          behavior: 'smooth'
        });
      }, 100)
      
    })
  },

  methods: {
    ...mapActions({
      getLectures: 'getLectures',
      updateLecture: 'updateLecture',
      deleteLecture: 'deleteLecture'
    }),

    duration(totSeconds) {
      if (totSeconds) {
        const seconds = totSeconds % 60;
        const minutes = (totSeconds-seconds)/60;
        return `${minutes}m:${seconds}s`;
      } else {
        return ""
      }
    },

    onStart() {
      console.log('Start...')
    },

    type(el) {
      if (el.course ) {
        return 'course'
      } else if (!!el.quiz && el.contents.length === 0) {
         return 'quiz'
      } else if ((!!el.quiz && el.contents.length > 0) ) {
        return 'lecture_quiz'
      }  else {
        return 'lecture'
      }
    },

   

    async updateRowOrder(evt){
     
      console.log(`New index: ${evt.newIndex}`)
      console.log(`Old index: ${evt.oldIndex}`)

      try {
        const res = await api.Lecture.updateRowOrder( { lecture: {id: evt.item.id, row_order_position: evt.newIndex } } )
          this.getLectures(res)
      } catch( err) {
          this.staticArr = [...this.course.lectures]
          this.$modal.show('error-modal');
          setTimeout( () => {
            this.$modal.hide('error-modal');
          }, 2000)
      }
    
    },

    removeLecture(lecture){
      if(confirm( `You are about to delete ${lecture.name} \n Deleting the lecture will remove all the data related to the lecture \n Are you really sure?` )){
        this.deleteLecture(lecture).then(resp => {
          location.reload();
        })
      }
    }

  }
  

}
</script>