<template>
  <div>
     <div class="flex mb-4">
        <BackButton :href="`/admin/pages`" :cssClass="'mr-4'" />
        <h2 class="text-xl  mb-2">{{ page.name.replace(/_/g, ' ') }}</h2>
     </div>

    <div class="mb-4">

      <vue-editor 
          :id="`editor`"
          v-model="body" 
          class="mb-4"
        >
        </vue-editor>

    </div>

    <button 
      class="Btn Btn--Save"
      @click="updatePage()">
      {{t('actions.save')}}
    </button>

  </div>
</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus'; 




export default {
  props: {
    page: Object
  },
  components: {
    BackButton,
    VueEditor
  },
  data() {
    return {
      body: this.page.body,
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]],
    }
  },
  mounted() {
  
  },
  beforeDestroy() {
    
  },
  methods: {

    updatePage(){
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Page.update(this.page, this.body)
        .then(res => {
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    }
  }
}
</script>

<style scoped>
 .editor__content{
   background-color: #fff;
 }
</style>