  <template>
    <div class="Path">
      <div class="Path__Hero mb-6">
        <div class="Path__Hero__Main">
          <span class="inline-block uppercase text-primary text-sm font-bold mb-2">{{ t('global.paths.s') }}</span>
          <h1 class="text-xl  mb-3">{{course.name}}</h1>
          <p v-if="course.subtitle" class="mb-6" v-html="course.subtitle" />
          <h2 class="mb-1 text-lg">{{t('pages.course.your_progress')}}:</h2>
          <div class="Path__Hero__Progress">
            <div class="mr-6">
              <span> {{course.percentageCompleted}}</span><span>{{t('pages.course.perc_completed')}}</span>
            </div>
            <div class="mr-6">
              <span> {{completedCourses.length}}/{{justCourses.length}}</span><span>{{t('pages.course.courses_completed')}}</span>
            </div>
            <div>
            </div>
          </div>
          <div class="mt-4">
            <a v-if="commentsEnabled" :href="`/courses/${course.id}/forum`" class="Btn Btn--Text">{{t('pages.course.go_to_forum')}}</a>
          </div>
          <a v-if="notEnrolled" :href="`${startCourseUrl}?success_message=true`" class="Btn Btn--Primary Path__Hero__Secondary__Go mt-4">{{ t('actions.enroll') }}</a>
        </div>
        <div :class="['Path__Hero__Secondary sm:pl-4', {'bg-image-sq': !course.image_url, 'course-image': course.image_url}]" :style="`background-image: url(${course.image_url})`">
    
          <div  v-if="pathDuration && pathDuration !== '0h: 0m'" class="IconTextBox mb-4">
           <h4 class="pr-4">{{ pathDuration }}</h4>
           <svg height="16" width="16" class="fill-current Boxed"> 
              <use xlink:href="#icon-time"></use>
           </svg>
          </div>
       
          <div class="IconTextBox" v-if="justCourses.length > 0">
            <h4 class="pr-4">{{justCourses.length}} {{t(`global.courses.${justCourses.length == 1 ? 's' : 'p'}`)}}</h4>
            <svg height="16" width="16" class="fill-current Boxed"> 
                <use xlink:href="#icon-book-reference"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="Box Box--Clear mb-8"  v-if="course.description">
        <button class="Path__Description flex items-center Btn Btn--Text gap-4" @click="descriptionOpen = !descriptionOpen">
           <span>{{t('pages.course.about_path')}}</span>
          <svg height="24" width="24" class="fill-current Boxed"> 
              <use :xlink:href="`#icon-cheveron-${descriptionOpen ? 'up' : 'down'}`"></use>
          </svg>
        </button>
        <transition name="slide">
          <div v-if="descriptionOpen">
            <div class="mb-6 RichText mt-4" v-html="course.description" />
          </div>
         </transition>
      </div>
    
      <div v-if="courseNotAvailable">
          <div class="Path__Courses pb-8 ml-auto mr-auto">
            <div class="Box Box--Clear CourseItem">
              <p v-if="courseNotStarted" class="font-bold mb-4 text-center text-lg">{{t('pages.course.course_will_be_available')}}  {{course.start_date | moment("DD/MM/YY - k:mm") }}</p>
              <p v-else-if="courseEnded" class="font-bold mb-4 text-center text-lg">{{t('pages.course.course_not_available_anymore')}}</p>
              <img src="../../images/illustrations/not-available.png" class="w-full max-w-sm mx-auto"/>
              <!-- <p class="text-center">Potrai iniziarlo il {{course.start_date | moment("DD/MM/YY - k:mm") }}</p> -->
            </div>
          </div>
        </div>
      
      <ul v-else-if="gridLayout" class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-4 pb-8 ml-auto mr-auto">
        <coursePathThumbnailVertical v-for="item in course.related_courses" :key="item.id"  v-if="isCourse(item)" :course="item" :pathId="course.id" :notEnrolled="notEnrolled" /> 
      </ul>
      <ul v-else class="Path__Courses pb-8 ml-auto mr-auto">
        <li v-for="item in course.related_courses" :key="item.id" :class="{CourseItem: isCourse(item), Lecture: !item.header && !isCourse(item), LectureHeader: item.header}">
          <p class="font-bold mb-4 text-center text-lg" v-if="item.header">{{item.name}}</p>
          <coursePathThumbnail :course="item" :pathId="course.id" v-else-if="isCourse(item)" :notEnrolled="notEnrolled" /> 
          <button v-else-if="!item.canShow" class="cannot-view" :content="t('pages.course.lecture_blocked')" v-tippy>{{item.name}}</button>
          <a v-else :href="`lectures/${item.id}`" :class="{completed: item.completed || item.percentage_value === 100}">
            {{item.name}}
            <b v-if="item.live && item.start_date">{{item.start_date | moment("D/M/YY k:mm") }}</b>
            <span class="Btn Btn--Primary Btn--Icon">
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </span>
            <span v-if="!item.canShow">{{ t('pages.course.lecture_blocked') }}</span>
          </a>
        </li>
      </ul>

    </div>

</template>

<script>


import sidebar from '../commons/sidebar'
import coursePathThumbnail from '../commons/coursePathThumbnail'
import coursePathThumbnailVertical from '../commons/coursePathThumbnailVertical'

export default {
  name: 'CoursePath',

  components: { 
    sidebar,
    coursePathThumbnail,
    coursePathThumbnailVertical
  },

  data() {
    return {
      isOpen: window.innerWidth < 768 ? false : true,
      descriptionOpen: false
    }
  },

  props: {
    currentUser: Object,
    course: Object,
    logo: String,
    tenant_name: String,
    notEnrolled: Boolean,
    gridLayout: Boolean,
    commentsEnabled: Boolean
  },

  channels: {
    
  },

  computed: {

    justCourses() {
      return this.course.related_courses.filter(el => {
        return el.course
      })
    },

    completedCourses() {
      return this.course.related_courses.filter(el => {
        return el.course && el.percentage_value === 100
      })
    },

    completedLectures() {
      let completedLects = this.course.completedLectures.length;
      this.justCourses.forEach(el => {
        completedLects += el.completedLectures.length
      })
      return completedLects
    },

    startCourseUrl() {
      if (this.notEnrolled) {
        return `/enrolls/${this.course.uuid}`
      } else {
        return this.course.nextLecture
      }
    },

    courseNotAvailable() {
      return this.course.start_date && new Date(this.course.start_date) > new Date() ||  this.course.end_date && new Date(this.course.end_date) < new Date()
    },

    courseNotStarted() {
      return !this.course.live_code && (this.course.start_date && new Date(this.course.start_date) > new Date())
    },

    courseEnded() {
      return !this.course.live_code && (this.course.end_date && new Date(this.course.end_date) < new Date())
    },

    
    pathDuration() {
      let duration = 0;
      this.course.related_courses.forEach(el => {
        if (el.duration) duration += el.duration
      });
      const totSeconds = duration;
      let seconds = totSeconds % 60;
      let minutes = Math.floor(totSeconds / 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60; // get the remainder of minutes
      if (seconds >= 30) {
        minutes += 1;
      }
      return `${hours}h: ${minutes}m`
    },

  },

  mounted() {
    
  },

  methods: {

    isCourse(el) {
      return el.course
    }
    

  }
}

</script>
<style lang="scss" scoped>
  .LecturePage__Toggle {
    right: -38px;
    .open & {
      right: 20px;
    }
  }

  
</style>
