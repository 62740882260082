<template>
  <div>

    <countdown 
        :time="new Date(lecture.start_date).getTime() - new Date().getTime()">
        <template slot-scope="props">
          <div class="CountDown pb-5">
          <div id="timer">
            <div class="days"> <div class="numbers">{{ props.days }}</div>D</div> 
            <div class="hours"> <div class="numbers">{{ props.hours }}</div>H</div> 
            <div class="minutes"><div class="numbers"> {{ props.minutes }}</div>M</div> 
            <div class="seconds"><div class="numbers">{{ props.seconds }}</div>S</div> </div>
            <h3 class="text-2xl mt-8">LIVE: {{lecture.start_date | moment("DD/MM/YY - k:mm") }}</h3>
          </div>
        </template>
      </countdown>

      <!-- todo: se il meeting viene chiuso erronamente,far si che al nuovo start time venga riaperto il link-->
    <div v-if="lecture.lecture_info['end_time'] || timeToStart < -(3 * 3600)" class="text-center">
       <h4 v-if="timeToStart < 0" class="Box Box--Clear text-2xl mb-4">{{t('pages.lecture.meeting_ended')}}</h4>
    </div>

    <div v-else>
      <div class="flex justify-center mt-3" v-if="lecture.lecture_type_id === 6 && timeToStart >43200" >
        <add-to-calendar-button
          :name="lecture.name"
          :startDate="dates.startDate"
          :endDate="dates.endDate"
          :startTime="dates.startTime"
          :endTime="dates.endTime"
          :label="`${t('actions.add_to_calendar')}!`"
          :location="content.content_code"
          :description="lecture.description"
          options="['Google', 'Outlook.com']"
          timeZone="Europe/Rome"
          trigger="hover"
          inline
          listStyle="dropdown"
          language="it"
        ></add-to-calendar-button>
      </div>
      

      <div class="flex justify-center mt-3" v-else-if="timeToStart > 43200">
          <add-to-calendar-button
            :name="lecture.name"
            :startDate="dates.startDate"
            :endDate="dates.endDate"
            :startTime="dates.startTime"
            :endTime="dates.endTime"
            :label="`${t('actions.add_to_calendar')}!`"
            :location="`${location}/courses/${course.id}/lectures/${lecture.id}`"
            :description="lecture.description"
            options="['Google', 'Outlook.com', 'MicrosoftTeams']"
            timeZone="Europe/Rome"
            trigger="hover"
            inline
            listStyle="dropdown"
            language="it"
          >
        </add-to-calendar-button>
      </div>
      
      <div v-if="content.content_type === 'attendance'" class="text-center Box Box--Clear mt-3">
        <h4><strong>Location:</strong></h4>
        <h4>{{ content.content_code }}</h4>
      </div>

      <div v-if=" timeToStart < 900 && content.content_type !== 'attendance'" class="text-center Box Box--Clear">
        <h4 v-if="timeToStart < 0" class="text-2xl mb-4">{{t('pages.lecture.meeting_running')}}</h4>
        <h4 v-else class="text-2xl mb-4">{{t('pages.lecture.meeting_beginning')}}</h4>
        <p class="mb-4">{{t('pages.lecture.join_instructions')}}</p>
        <a v-if="content.content_type !== 'teams'" :href="`/zoom/join?${lecture.event_type || 'meeting'}_id=${content.content_code}`" class="Btn Btn--Primary">{{t('actions.join')}}</a>
        <a v-else :href="`${content.content_code}`" target="_blank" class="Btn Btn--Primary">{{t('actions.join')}}</a>
      </div>

    </div>

  </div>
</template>

<script>

import 'add-to-calendar-button'
import moment from "moment";


export default {
  props: {
    lecture: Object,
    content: Object,
    course: Object
  },
  data() {
    return {
      timeToStart: Number,
      location: window.location.origin
    }
  },
  mounted() {
    this.updateTimeToStart();
    console.log(this.lecture)
    this.interval = setInterval(() => {
      this.updateTimeToStart();
    },1000);
  },
  computed: {
  dates() {
    const startDate = moment(this.lecture.start_date).format('YYYY-MM-DD');
    const startTime = moment(this.lecture.start_date).format('HH:mm');
    const endDate = moment(this.lecture.start_date).format('YYYY-MM-DD');
    const endTime = moment(this.lecture.start_date).add(this.lecture.duration, 'minutes').format('HH:mm');

    console.log("Computed dates for add-to-calendar-button:", {
      startDate,
      startTime,
      endDate,
      endTime,
      location: `${this.location}/courses/${this.course.id}/lectures/${this.lecture.id}`,
    });

    return { startDate, startTime, endDate, endTime };
  },
},

  methods: {
    updateTimeToStart(){
      this.timeToStart = ((new Date(this.lecture.start_date) - Date.now()) / 1000)
    },
  }
}
</script>